import { useInfiniteQuery } from '@tanstack/react-query';

import { profileKeys } from '@/entities/profile/model/query-keys';
import { GetUserTransactions200 } from '@/shared/api/codegen/demuzo';
import { walletApi } from '@/shared/api/config';

import { mapApiTransactionsToTransactions } from '../lib';

export const useGetUserTransactionsInfinity = () => {
  return useInfiniteQuery({
    getNextPageParam: (lastPage: GetUserTransactions200) => {
      if (lastPage.total > lastPage.limit + lastPage.offset) {
        return lastPage.offset / lastPage.limit + 1;
      }
      return undefined;
    },
    queryKey: profileKeys.transactionsInfinity,
    initialPageParam: 0,
    refetchInterval: 4000,
    queryFn: async ({ pageParam }) => {
      const response = await walletApi.getWalletV1Transactions({
        limit: 1000,
        offset: pageParam * 10,
      });

      const mappedTransactions = response.items.map(
        mapApiTransactionsToTransactions,
      );

      return {
        ...response,
        items: mappedTransactions,
      };
    },
  });
};
