'use client';

import { Image } from '@chakra-ui/next-js';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { signIn } from 'next-auth/react';
import { useTranslations } from 'next-intl';
import { useEffect, useRef, useState } from 'react';

import Button from '@ui-kit/atoms/button/button';

import { HowItsWork } from './how-its-work';
import initAnimatedBackground from './initAnimatedBackground';

import './styles.css';

const videoURL =
  'https://videobox-prod.s3.eu-central-1.amazonaws.com/raw/demuzo_how_its_work.mp4';

export const HomeHeroBanner = ({
  isAuthenticated,
}: {
  isAuthenticated: boolean;
}) => {
  const t = useTranslations();
  const canvasRef = useRef(null);
  const canvasId = 'animatedBackground';
  const [videoModalIsVisible, displayVideoModal] = useState(false);

  const handleSignIn = async () => {
    await signIn('keycloak');
  };

  useEffect(() => {
    if (canvasRef.current) {
      initAnimatedBackground(canvasRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvasRef.current]);

  const handlePlayVideo = () => {
    displayVideoModal(true);
  };

  return (
    <div className='home-hero-banner'>
      <canvas
        id={canvasId}
        ref={canvasRef}
      />

      <h1>{t('HomePageHeroBanner.header')}</h1>
      <div className='actions'>
        <button
          className='play-video'
          onClick={handlePlayVideo}
        >
          <Image
            src='/images/play-video-icon.svg'
            alt=''
            width={62}
            height={62}
          />
          <div className='text'>
            <div className='heading'>
              {t('HomePageHeroBanner.videoHelp.title')}
            </div>
            <div className='description'>
              {t('HomePageHeroBanner.videoHelp.title')}
            </div>
          </div>
        </button>

        {!isAuthenticated && (
          <Button
            className='get-access'
            colorScheme='green'
            size='xl'
            variant='solid'
            onClick={handleSignIn}
          >
            <Image
              src='/images/key-icon.svg'
              alt=''
              width={23}
              height={11}
            />
            {t('HomePageHeroBanner.CTAButtonText')}
          </Button>
        )}
      </div>

      <HowItsWork />

      <Modal
        isOpen={videoModalIsVisible}
        onClose={() => displayVideoModal(false)}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            borderBottomWidth='0.5px'
            borderColor='states.text&icon.disabled'
            p='20px'
          >
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody p='16px'>
            {videoModalIsVisible && (
              <video
                className='howItWorksVideo'
                width='100%'
                height='100%'
                autoPlay
              >
                <source
                  src={videoURL}
                  type='video/mp4'
                />
              </video>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};
