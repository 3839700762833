'use client';

import { CacheProvider } from '@chakra-ui/next-js';
import { CSSReset, ChakraProvider } from '@chakra-ui/react';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import * as Sentry from '@sentry/nextjs';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import Script from 'next/script';
import type { Session } from 'next-auth';
import { SessionProvider } from 'next-auth/react';
import { AbstractIntlMessages, NextIntlClientProvider } from 'next-intl';
import { useEffect, useState } from 'react';
import * as CookieConsent from 'vanilla-cookieconsent';

import { PlayerProvider } from '@/features/audio-player';
import { SESSION_MAX_AGE } from '@/shared/constants/auth-session';
import {
  ibmPlexMono,
  ibmPlexSans,
  naNMetrifyAWide,
} from '@/shared/fonts/fonts';
import { getQueryClient } from '@/shared/utils/get-query-client';

import demuzoTheme from '../shared/ui-kit/theme';

import 'vanilla-cookieconsent/dist/cookieconsent.css';
import './cc.css';

const PAYPAL_CLIENT_ID = process.env.NEXT_PUBLIC_PAYPAL_CLIENT_ID || '';

export function Providers({
  children,
  session,
  messages,
  locale,
}: {
  children: React.ReactNode;
  session: Session | null;
  locale: string;
  messages: AbstractIntlMessages;
}) {
  const queryClient = getQueryClient();
  const [isConsent, setIsConsent] = useState(false);
  Sentry.addEventProcessor((event) => {
    if (!isConsent) {
      return null;
    }

    return event;
  });
  useEffect(() => {
    CookieConsent.run({
      onConsent: ({ cookie }) => {
        setIsConsent(cookie.categories.includes('analytics'));
      },
      onChange: ({ changedCategories }) => {
        setIsConsent(changedCategories.includes('analytics'));
      },
      categories: {
        necessary: {
          enabled: true, // this category is enabled by default
          readOnly: true, // this category cannot be disabled
        },
        analytics: {},
      },
      guiOptions: {
        consentModal: {
          layout: 'box inline',
          position: 'bottom center',
          flipButtons: false,
          equalWeightButtons: true,
        },
        preferencesModal: {
          layout: 'box',
          // position: 'left right',
          flipButtons: false,
          equalWeightButtons: true,
        },
      },
      language: {
        default: 'en',
        translations: {
          en: {
            consentModal: {
              title: 'We use cookies',
              description:
                'To make the site work well, we use cookies and analytics services. By remaining on the page, you agree to the Cookie Processing Procedure (Privacy Policy, Section 7).',
              acceptAllBtn: 'Accept all',
              acceptNecessaryBtn: 'Reject all',
              showPreferencesBtn: 'Manage Individual preferences',
            },
            preferencesModal: {
              title: 'Manage cookie preferences',
              acceptAllBtn: 'Accept all',
              acceptNecessaryBtn: 'Reject all',
              savePreferencesBtn: 'Accept current selection',
              closeIconLabel: 'Close modal',
              sections: [
                {
                  title: 'Strictly Necessary cookies',
                  description:
                    'These cookies are essential for the proper functioning of the website and cannot be disabled.',
                  linkedCategory: 'necessary',
                },
                {
                  title: 'Performance and Analytics',
                  description:
                    'These cookies collect information about how you use our website. All of the data is anonymized and cannot be used to identify you.',
                  linkedCategory: 'analytics',
                },
                {
                  title: 'More information',
                  description:
                    'To make the site work well, we use cookies and analytics services. By remaining on the page, you agree to the Cookie Processing Procedure (Privacy Policy, Section 7).',
                },
              ],
            },
          },
        },
      },
    });
  }, []);

  return (
    <>
      {isConsent ? (
        <Script id='clarity-script'>
          {`(function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", "omzamwdsgi");`}
        </Script>
      ) : null}
      <NextIntlClientProvider
        locale={locale}
        messages={messages}
        timeZone={'Europe/Vienna'}
        defaultTranslationValues={{
          newLine: (chunks) => (
            <>
              <br />
              {chunks}
            </>
          ),
        }}
      >
        <SessionProvider
          session={session || undefined}
          refetchInterval={SESSION_MAX_AGE}
        >
          <QueryClientProvider client={queryClient}>
            <CacheProvider>
              <ChakraProvider
                portalZIndex={100000}
                theme={demuzoTheme(
                  ibmPlexSans.style.fontFamily,
                  naNMetrifyAWide.style.fontFamily,
                  ibmPlexMono.style.fontFamily,
                )}
              >
                <CSSReset />
                <PayPalScriptProvider
                  options={{
                    environment:
                      process.env.NODE_ENV === 'production'
                        ? 'production'
                        : 'sandbox',
                    clientId: PAYPAL_CLIENT_ID,
                    components: ['card-fields', 'buttons'],
                  }}
                >
                  <PlayerProvider>{children}</PlayerProvider>
                </PayPalScriptProvider>
              </ChakraProvider>
            </CacheProvider>
            <ReactQueryDevtools
              initialIsOpen={false}
              buttonPosition='top-left'
            />
          </QueryClientProvider>
        </SessionProvider>
      </NextIntlClientProvider>
    </>
  );
}
