/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostPaymentsV1DepositWithIDResponse
 */
export interface PostPaymentsV1DepositWithIDResponse {
    /**
     * 
     * @type {string}
     * @memberof PostPaymentsV1DepositWithIDResponse
     */
    orderId: string;
}

/**
 * Check if a given object implements the PostPaymentsV1DepositWithIDResponse interface.
 */
export function instanceOfPostPaymentsV1DepositWithIDResponse(value: object): boolean {
    if (!('orderId' in value)) return false;
    return true;
}

export function PostPaymentsV1DepositWithIDResponseFromJSON(json: any): PostPaymentsV1DepositWithIDResponse {
    return PostPaymentsV1DepositWithIDResponseFromJSONTyped(json, false);
}

export function PostPaymentsV1DepositWithIDResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostPaymentsV1DepositWithIDResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'orderId': json['orderId'],
    };
}

export function PostPaymentsV1DepositWithIDResponseToJSON(value?: PostPaymentsV1DepositWithIDResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'orderId': value['orderId'],
    };
}

