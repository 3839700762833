'use client';

import { Container, Flex } from '@chakra-ui/react';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';
import './styles.css';

const SLIDE_INTERVAL = 3000;

export const HowItsWork = () => {
  const t = useTranslations();
  const [activeSlide, setActiveSlide] = useState(0);

  const slides = [
    {
      title: t('HomePageHowItWorksBlock.1.title'),
      description: t('HomePageHowItWorksBlock.1.description'),
    },
    {
      title: t('HomePageHowItWorksBlock.2.title'),
      description: t('HomePageHowItWorksBlock.2.description'),
    },
    {
      title: t('HomePageHowItWorksBlock.3.title'),
      description: t('HomePageHowItWorksBlock.3.description'),
    },
    {
      title: t('HomePageHowItWorksBlock.4.title'),
      description: t('HomePageHowItWorksBlock.4.description'),
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveSlide((prev) => {
        if (prev === slides.length - 1) {
          return 0;
        }
        return prev + 1;
      });
    }, SLIDE_INTERVAL);

    return () => clearInterval(interval);
  }, []);

  return (
    <Container
      flex={1}
      as='main'
      display='flex'
      flexDirection='column'
      maxWidth={{
        base: 'full',
        md: 'container.md',
        lg: 'container.lg',
        xl: 'container.xl',
      }}
      paddingInlineStart={{ base: 0, md: '20px', lg: '40px' }}
      paddingInlineEnd={{ base: 0, md: '20px', lg: '40px' }}
    >
      <Flex
        flex={1}
        direction='column'
        position='relative'
      >
        <ul className='how-its-work-list'>
          {slides.map((slide, index) => (
            <li
              className={activeSlide === index ? 'active' : undefined}
              key={index}
            >
              <div className='num'>{index + 1}.</div>
              <h3>{slide.title}</h3>
              <p>{slide.description}</p>
            </li>
          ))}
        </ul>
      </Flex>
    </Container>
  );
};
