/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostPaymentsV1DepositWithIDRequest
 */
export interface PostPaymentsV1DepositWithIDRequest {
    /**
     * 
     * @type {number}
     * @memberof PostPaymentsV1DepositWithIDRequest
     */
    amount: number;
}

/**
 * Check if a given object implements the PostPaymentsV1DepositWithIDRequest interface.
 */
export function instanceOfPostPaymentsV1DepositWithIDRequest(value: object): boolean {
    if (!('amount' in value)) return false;
    return true;
}

export function PostPaymentsV1DepositWithIDRequestFromJSON(json: any): PostPaymentsV1DepositWithIDRequest {
    return PostPaymentsV1DepositWithIDRequestFromJSONTyped(json, false);
}

export function PostPaymentsV1DepositWithIDRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostPaymentsV1DepositWithIDRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'amount': json['amount'],
    };
}

export function PostPaymentsV1DepositWithIDRequestToJSON(value?: PostPaymentsV1DepositWithIDRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'amount': value['amount'],
    };
}

