/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GetPaymentsPaypal200,
  GetPaymentsV1PaypalError,
  IntegrationError,
  InternalServerError500,
  PostDepositWithIdCapture422,
  PostFeeResponse,
  PostPaymentsPaypal200,
  PostPaymentsPaypalRequest,
  PostPaymentsV1DepositRequest,
  PostPaymentsV1DepositResponse,
  PostPaymentsV1DepositWithIDRequest,
  PostPaymentsV1DepositWithIDResponse,
  PostPaymentsV1FeeError,
  PostPaymentsV1FeeRequest,
} from '../models/index';
import {
    GetPaymentsPaypal200FromJSON,
    GetPaymentsPaypal200ToJSON,
    GetPaymentsV1PaypalErrorFromJSON,
    GetPaymentsV1PaypalErrorToJSON,
    IntegrationErrorFromJSON,
    IntegrationErrorToJSON,
    InternalServerError500FromJSON,
    InternalServerError500ToJSON,
    PostDepositWithIdCapture422FromJSON,
    PostDepositWithIdCapture422ToJSON,
    PostFeeResponseFromJSON,
    PostFeeResponseToJSON,
    PostPaymentsPaypal200FromJSON,
    PostPaymentsPaypal200ToJSON,
    PostPaymentsPaypalRequestFromJSON,
    PostPaymentsPaypalRequestToJSON,
    PostPaymentsV1DepositRequestFromJSON,
    PostPaymentsV1DepositRequestToJSON,
    PostPaymentsV1DepositResponseFromJSON,
    PostPaymentsV1DepositResponseToJSON,
    PostPaymentsV1DepositWithIDRequestFromJSON,
    PostPaymentsV1DepositWithIDRequestToJSON,
    PostPaymentsV1DepositWithIDResponseFromJSON,
    PostPaymentsV1DepositWithIDResponseToJSON,
    PostPaymentsV1FeeErrorFromJSON,
    PostPaymentsV1FeeErrorToJSON,
    PostPaymentsV1FeeRequestFromJSON,
    PostPaymentsV1FeeRequestToJSON,
} from '../models/index';

export interface PostPaymentsV1DepositOperationRequest {
    postPaymentsV1DepositRequest?: PostPaymentsV1DepositRequest;
}

export interface PostPaymentsV1DepositWithIdRequest {
    postPaymentsV1DepositWithIDRequest?: PostPaymentsV1DepositWithIDRequest;
}

export interface PostPaymentsV1DepositWithIdCaptureRequest {
    orderId: string;
}

export interface PostPaymentsV1FeeOperationRequest {
    postPaymentsV1FeeRequest?: PostPaymentsV1FeeRequest;
}

export interface PostPaymentsV1PaypalRequest {
    postPaymentsPaypalRequest?: PostPaymentsPaypalRequest;
}

/**
 * 
 */
export class PaymentsApi extends runtime.BaseAPI {

    /**
     * /payments/v1/paypal
     */
    async deletePaymentsV1PaypalRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/payments/v1/paypal`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * /payments/v1/paypal
     */
    async deletePaymentsV1Paypal(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deletePaymentsV1PaypalRaw(initOverrides);
    }

    /**
     * /payments/v1/paypal
     */
    async getPaymentsV1PaypalRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetPaymentsPaypal200>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/payments/v1/paypal`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPaymentsPaypal200FromJSON(jsonValue));
    }

    /**
     * /payments/v1/paypal
     */
    async getPaymentsV1Paypal(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetPaymentsPaypal200> {
        const response = await this.getPaymentsV1PaypalRaw(initOverrides);
        return await response.value();
    }

    /**
     * Инициация пополнения депозита
     * /payments/v1/deposit
     */
    async postPaymentsV1DepositRaw(requestParameters: PostPaymentsV1DepositOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PostPaymentsV1DepositResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/payments/v1/deposit`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostPaymentsV1DepositRequestToJSON(requestParameters['postPaymentsV1DepositRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostPaymentsV1DepositResponseFromJSON(jsonValue));
    }

    /**
     * Инициация пополнения депозита
     * /payments/v1/deposit
     */
    async postPaymentsV1Deposit(requestParameters: PostPaymentsV1DepositOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PostPaymentsV1DepositResponse> {
        const response = await this.postPaymentsV1DepositRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * /payments/v1/depositWithID
     */
    async postPaymentsV1DepositWithIdRaw(requestParameters: PostPaymentsV1DepositWithIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PostPaymentsV1DepositWithIDResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/payments/v1/depositWithID`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostPaymentsV1DepositWithIDRequestToJSON(requestParameters['postPaymentsV1DepositWithIDRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostPaymentsV1DepositWithIDResponseFromJSON(jsonValue));
    }

    /**
     * /payments/v1/depositWithID
     */
    async postPaymentsV1DepositWithId(requestParameters: PostPaymentsV1DepositWithIdRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PostPaymentsV1DepositWithIDResponse> {
        const response = await this.postPaymentsV1DepositWithIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * /payments/v1/depositWithID/{orderId}/capture
     */
    async postPaymentsV1DepositWithIdCaptureRaw(requestParameters: PostPaymentsV1DepositWithIdCaptureRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['orderId'] == null) {
            throw new runtime.RequiredError(
                'orderId',
                'Required parameter "orderId" was null or undefined when calling postPaymentsV1DepositWithIdCapture().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/payments/v1/depositWithID/{orderId}/capture`.replace(`{${"orderId"}}`, encodeURIComponent(String(requestParameters['orderId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * /payments/v1/depositWithID/{orderId}/capture
     */
    async postPaymentsV1DepositWithIdCapture(requestParameters: PostPaymentsV1DepositWithIdCaptureRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postPaymentsV1DepositWithIdCaptureRaw(requestParameters, initOverrides);
    }

    /**
     * Метод для запроса комиссии для пополнения или вывода средств
     * /payments/v1/fee
     */
    async postPaymentsV1FeeRaw(requestParameters: PostPaymentsV1FeeOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PostFeeResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/payments/v1/fee`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostPaymentsV1FeeRequestToJSON(requestParameters['postPaymentsV1FeeRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostFeeResponseFromJSON(jsonValue));
    }

    /**
     * Метод для запроса комиссии для пополнения или вывода средств
     * /payments/v1/fee
     */
    async postPaymentsV1Fee(requestParameters: PostPaymentsV1FeeOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PostFeeResponse> {
        const response = await this.postPaymentsV1FeeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Завершения флоу авторизации через paypal для привязки paypal аккаунта
     * /payments/v1/paypal
     */
    async postPaymentsV1PaypalRaw(requestParameters: PostPaymentsV1PaypalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PostPaymentsPaypal200>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/payments/v1/paypal`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostPaymentsPaypalRequestToJSON(requestParameters['postPaymentsPaypalRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostPaymentsPaypal200FromJSON(jsonValue));
    }

    /**
     * Завершения флоу авторизации через paypal для привязки paypal аккаунта
     * /payments/v1/paypal
     */
    async postPaymentsV1Paypal(requestParameters: PostPaymentsV1PaypalRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PostPaymentsPaypal200> {
        const response = await this.postPaymentsV1PaypalRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
