const vertShader = `
      precision mediump float;

      varying vec2 vUv;
      attribute vec2 a_position;

      void main() {
          vUv = .5 * (a_position + 1.);
          gl_Position = vec4(a_position, 0.0, 1.0);
      }
`;

const fragShader = `
     precision mediump float;

      varying vec2 vUv;
      uniform float u_time;
      uniform float u_ratio;
      uniform vec2 u_pointer_position;
      uniform float u_scroll_progress;

      vec2 rotate(vec2 uv, float th) {
          return mat2(cos(th), sin(th), -sin(th), cos(th)) * uv;
      }

      float neuro_shape(vec2 uv, float t, float p) {
          vec2 sine_acc = vec2(0.);
          vec2 res = vec2(0.);
          float scale = 8.;

          for (int j = 0; j < 20; j++) {
              uv = rotate(uv, 1.3);
              sine_acc = rotate(sine_acc, 1.);
              vec2 layer = uv * scale + float(j) + sine_acc - t;
              sine_acc += sin(layer);
              res += (.5 + .5 * cos(layer)) / scale;
              scale *= (1.2 - .05 * p);
          }
          return res.x + res.y;
      }

      void main() {
          vec2 uv = .3 * vUv;
          uv.x *= u_ratio;

          vec2 pointer = vUv - u_pointer_position;
          pointer.x *= u_ratio;
          float p = clamp(length(pointer), 0., 1.);
          p = .5 * pow(1. - p, 2.);

          float t = .001 * u_time;
          vec3 color = vec3(0.2);

          float noise = neuro_shape(uv, t, p);

          noise = 1.2 * pow(noise, 3.);
          noise += pow(noise, 10.);
          noise = max(.0, noise - .5);
          noise *= (1. - length(vUv - .5));

          color = normalize(vec3(.99, .9 + .4 * cos(4. * u_scroll_progress), .3 + .2 * sin(5. * u_scroll_progress)));

          color = color * noise;

          gl_FragColor = vec4(color, noise);
      }
`;

interface TUniforms {
  [key: string]: WebGLUniformLocation | null;
  u_time: WebGLUniformLocation | null;
  u_ratio: WebGLUniformLocation | null;
  u_pointer_position: WebGLUniformLocation | null;
  u_scroll_progress: WebGLUniformLocation | null;
}

interface Pointer {
  x: number;
  y: number;
  tX: number;
  tY: number;
}

const initAnimatedBackground = (canvasEl: HTMLCanvasElement) => {
  if (canvasEl === null) {
    return null;
  }

  const devicePixelRatio = Math.min(window.devicePixelRatio, 2);
  let program: WebGLProgram | null = null;
  let uniforms: TUniforms;
  let gl: WebGLRenderingContext | null = null;

  const pointer: Pointer = {
    x: 0,
    y: 0,
    tX: 0,
    tY: 0,
  };

  gl = initShader();
  if (!gl) return null;

  resizeCanvas();
  render();

  function initShader(): WebGLRenderingContext | null {
    const context = (canvasEl.getContext('webgl') ||
      canvasEl.getContext(
        'experimental-webgl',
      )) as WebGLRenderingContext | null;

    if (!context) {
      console.error('WebGL is not supported by your browser.');
      return null;
    }

    gl = context;

    const vertexShader = createShader(gl, vertShader, gl.VERTEX_SHADER);
    const fragmentShader = createShader(gl, fragShader, gl.FRAGMENT_SHADER);

    if (!vertexShader || !fragmentShader) return null;

    program = createShaderProgram(gl, vertexShader, fragmentShader);
    if (!program) return null;

    gl.useProgram(program);

    uniforms = {
      u_time: gl.getUniformLocation(program, 'u_time'),
      u_ratio: gl.getUniformLocation(program, 'u_ratio'),
      u_pointer_position: gl.getUniformLocation(program, 'u_pointer_position'),
      u_scroll_progress: gl.getUniformLocation(program, 'u_scroll_progress'),
    };

    const vertices = new Float32Array([-1, -1, 1, -1, -1, 1, 1, 1]);
    const vertexBuffer = gl.createBuffer();
    if (!vertexBuffer) return null;

    gl.bindBuffer(gl.ARRAY_BUFFER, vertexBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, vertices, gl.STATIC_DRAW);

    const positionLocation = gl.getAttribLocation(program, 'a_position');
    gl.enableVertexAttribArray(positionLocation);
    gl.vertexAttribPointer(positionLocation, 2, gl.FLOAT, false, 0, 0);

    return gl;
  }

  function createShader(
    gl: WebGLRenderingContext,
    sourceCode: string,
    type: number,
  ): WebGLShader | null {
    const shader = gl.createShader(type);
    if (!shader) return null;

    gl.shaderSource(shader, sourceCode);
    gl.compileShader(shader);

    if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
      console.error('Shader compilation error:', gl.getShaderInfoLog(shader));
      gl.deleteShader(shader);
      return null;
    }

    return shader;
  }

  function createShaderProgram(
    gl: WebGLRenderingContext,
    vertexShader: WebGLShader,
    fragmentShader: WebGLShader,
  ): WebGLProgram | null {
    const program = gl.createProgram();
    if (!program) return null;

    gl.attachShader(program, vertexShader);
    gl.attachShader(program, fragmentShader);
    gl.linkProgram(program);

    if (!gl.getProgramParameter(program, gl.LINK_STATUS)) {
      console.error('Program linking error:', gl.getProgramInfoLog(program));
      return null;
    }

    return program;
  }

  function render() {
    if (!gl || !program) return;

    gl.useProgram(program);

    const currentTime = performance.now();
    pointer.x += (pointer.tX - pointer.x) * 0.5;
    pointer.y += (pointer.tY - pointer.y) * 0.5;

    if (uniforms.u_time) {
      gl.uniform1f(uniforms.u_time, currentTime);
    }
    if (uniforms.u_pointer_position) {
      gl.uniform2f(
        uniforms.u_pointer_position,
        pointer.x / window.innerWidth,
        1 - pointer.y / window.innerHeight,
      );
    }
    if (uniforms.u_scroll_progress) {
      gl.uniform1f(uniforms.u_scroll_progress, 0);
    }

    gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);
    requestAnimationFrame(render);
  }

  function resizeCanvas() {
    if (!gl || !program) return;

    gl.useProgram(program);

    canvasEl.width = window.innerWidth * devicePixelRatio;
    canvasEl.height = window.innerHeight * devicePixelRatio;

    if (uniforms.u_ratio) {
      gl.uniform1f(uniforms.u_ratio, canvasEl.width / canvasEl.height);
    }
    gl.viewport(0, 0, canvasEl.width, canvasEl.height);
  }

  // Add event listener for window resize
  window.addEventListener('resize', resizeCanvas);

  return () => {
    window.removeEventListener('resize', resizeCanvas);
  };
};

export default initAnimatedBackground;
