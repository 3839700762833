/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostDepositWithIdCapture422
 */
export interface PostDepositWithIdCapture422 {
    /**
     * 
     * @type {string}
     * @memberof PostDepositWithIdCapture422
     */
    code?: PostDepositWithIdCapture422CodeEnum;
    /**
     * 
     * @type {string}
     * @memberof PostDepositWithIdCapture422
     */
    message?: string;
}


/**
 * @export
 */
export const PostDepositWithIdCapture422CodeEnum = {
    UNPROCESSABLE_NUMBER: 'UNPROCESSABLE_NUMBER'
} as const;
export type PostDepositWithIdCapture422CodeEnum = typeof PostDepositWithIdCapture422CodeEnum[keyof typeof PostDepositWithIdCapture422CodeEnum];


/**
 * Check if a given object implements the PostDepositWithIdCapture422 interface.
 */
export function instanceOfPostDepositWithIdCapture422(value: object): boolean {
    return true;
}

export function PostDepositWithIdCapture422FromJSON(json: any): PostDepositWithIdCapture422 {
    return PostDepositWithIdCapture422FromJSONTyped(json, false);
}

export function PostDepositWithIdCapture422FromJSONTyped(json: any, ignoreDiscriminator: boolean): PostDepositWithIdCapture422 {
    if (json == null) {
        return json;
    }
    return {
        
        'code': json['code'] == null ? undefined : json['code'],
        'message': json['message'] == null ? undefined : json['message'],
    };
}

export function PostDepositWithIdCapture422ToJSON(value?: PostDepositWithIdCapture422 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'code': value['code'],
        'message': value['message'],
    };
}

