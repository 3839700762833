'use client';

import { Link } from '@chakra-ui/next-js';
import { Box, Button, Flex, SimpleGrid, Text } from '@chakra-ui/react';
import { useRouter } from 'next/navigation';
import { signIn } from 'next-auth/react';
import { useTranslations } from 'next-intl';
import { MouseEvent, useCallback, useState } from 'react';
import { Swiper } from 'swiper/types';

import { LIMIT, OFFSET } from '@/entities/constants';
import { useGetNews } from '@/entities/news';
import { useGetProductsAll } from '@/entities/product';
import { mapApiProductToProduct } from '@/entities/product/model/lib';
import { usePlayList } from '@/features/audio-player';
import { usePlayerData } from '@/features/audio-player/hooks/use-player-data';
import { useFavorites } from '@/features/favorites';
import {
  CrowdFundingCampaignStatusEnum,
  GetFeatured200,
  GetNews200,
  GetProducts200,
} from '@/shared/api/codegen/demuzo';
import { FaqBlock } from '@/shared/components/faq-block';
// import HomeHowItWorks from '@/shared/components/home-how-it-works/home-how-it-works';
import { HomePartners } from '@/shared/components/home-partners';
// import { HomePresent } from '@/shared/components/home-present';
import { NewsSwiper } from '@/shared/components/news-swiper';
import { IconButton } from '@/shared/ui-kit/atoms/icon-button';
import { useIsMobile } from '@/shared/ui-kit/hooks/useIsMobile';
import IconChevronLeft from '@/shared/ui-kit/icons/icon-chevron-left';
import IconChevronRight from '@/shared/ui-kit/icons/icon-chevron-right';
import { Section } from '@/shared/ui-kit/molecules/section';
import { CampaignCard } from '@/shared/ui-kit/organisms/campaign-card';
import { ProjectCard } from '@/shared/ui-kit/organisms/project-card';
import {
  calculateDaysLeft,
  calculateProgressStartDay,
} from '@/shared/utils/calculate-days-left';

import FeaturedWidget from '../../widgets/featured-widget/featured-widget';

type TProps = { isAuthenticated: boolean; initData?: TInitData };

type TInitData = {
  products: GetProducts200;
  news: GetNews200;
  featured: GetFeatured200;
};

export const HomePage = ({ isAuthenticated, initData }: TProps) => {
  const t = useTranslations();
  const isMobile = useIsMobile();
  const [controlledSwiperNews, setControlledSwiperNews] =
    useState<Swiper | null>(null);

  const { currentTrack } = usePlayList();
  const { data: playerData, updateData } = usePlayerData();
  const { push } = useRouter();

  const { data: dataProducts } = useGetProductsAll({
    limit: LIMIT,
    offset: OFFSET,
  });

  const productData =
    dataProducts || initData?.products.items.map(mapApiProductToProduct);

  const { data: dataNews } = useGetNews({
    limit: LIMIT,
    offset: OFFSET,
    initData: initData?.news,
  });
  const { favorites, handleToggle } = useFavorites();
  const handleSignIn = async () => {
    push('/sign-in?callbackUrl=/campaigns');
  };

  const handleToggleFavoriteFeature = ({
    evt,
    productId,
  }: {
    evt: MouseEvent<HTMLButtonElement>;
    productId: string;
  }) => {
    evt.preventDefault();
    evt.stopPropagation();

    handleToggle(productId);
  };

  const onAllCampaigns = () => {
    push('/campaigns');
  };

  const onBuyClick = useCallback(
    async (id: string) => {
      const callbackUrl = `/project/purchase/${id}`;
      if (!isAuthenticated) {
        await signIn('keycloak', { callbackUrl });
        return;
      }

      push(callbackUrl);
    },
    [isAuthenticated, push],
  );

  return (
    <Box>
      {/* <HomePresent
        isAuthenticated={isAuthenticated}
        onSignIn={handleSignIn}
      /> */}

      {/* <Section
        mt={{ lg: '100px' }}
        withPadding={isMobile}
        header={t('HomePage.howItWorksTitle')}
        pHeader={{ base: '20px', lg: 0 }}
      >
        <Box mt={{ xl: '-50px' }}>
          <HomeHowItWorks />
        </Box>
      </Section> */}

      <FeaturedWidget initData={initData?.featured} />

      <Section
        isVisible={Boolean(productData?.length)}
        withPadding={isMobile}
        header={t('HomePage.campaigns')}
        mt={{ lg: '100px' }}
        pHeader={{ base: '20px', lg: 0 }}
      >
        <Box
          mt={{ xl: '-50px' }}
          maxW='1220px'
          ml='auto'
          w='full'
        >
          <SimpleGrid
            columns={{ base: 1, md: 3, lg: 4 }}
            spacingX={{ base: '10px', md: '20px' }}
            spacingY={{ base: '10px', md: '60px' }}
          >
            {productData?.map((product) => {
              const snippet = product.snippets.at(0);
              const isFavorite = favorites.includes(product.id);
              const isCurrentTrack = snippet && snippet.id === currentTrack?.id;
              const needUpdateData =
                playerData?.isFavoriteCampaign !== product.isFavorite;
              if (isCurrentTrack && needUpdateData) {
                updateData({ isFavoriteCampaign: product.isFavorite });
              }

              const handleCurrentPlay = () => {
                updateData({
                  currentCampaignId: product.id,
                  isFavoriteCampaign: product.isFavorite,
                  canBuy: product.crowdFundingCampaign.status === 'in_progress',
                });
              };
              const genres = product.secondaryGenre
                ? `${product.primaryGenre} · ${product.secondaryGenre}`
                : product.primaryGenre;
              return isMobile ? (
                <ProjectCard
                  href={`/project/${product.id}`}
                  key={product.id}
                  snippet={snippet}
                  imageUrl={product.coverUrls.at(0) ?? ''}
                  onPlay={handleCurrentPlay}
                  onToggleFavoriteFeature={(evt) => {
                    handleToggleFavoriteFeature({
                      evt,
                      productId: product.id,
                    });
                  }}
                  isFavorite={isFavorite}
                  daysLeft={calculateDaysLeft(
                    product.crowdFundingCampaign.startDateTime,
                    product.crowdFundingCampaign.endDateTime,
                  )}
                  progress={Number(product.crowdFundingCampaign.progress)}
                  artistName={product.artist
                    .map((item) => item.name)
                    .join(' x ')}
                  productName={product.name}
                  genres={genres}
                  totalInvested={product.crowdFundingCampaign.totalInvested}
                  limit={product.crowdFundingCampaign.successThreshold}
                  crowdFundingCampaignStatus={
                    product.crowdFundingCampaign.status
                  }
                  progressToStart={
                    product.crowdFundingCampaign.createdAt
                      ? calculateProgressStartDay(
                          product.crowdFundingCampaign.createdAt,
                          product.crowdFundingCampaign.startDateTime,
                        )
                      : 1
                  }
                />
              ) : (
                <CampaignCard
                  isButtonDivider
                  sharePrice={product.crowdFundingCampaign.sharePrice}
                  snippet={snippet}
                  withBg={false}
                  rounded={false}
                  size='sm'
                  href={`/project/${product.id}`}
                  key={product.id}
                  imageUrl={product.coverUrls.at(0) ?? ''}
                  onPlay={handleCurrentPlay}
                  onToggleFavoriteFeature={(evt) => {
                    handleToggleFavoriteFeature({
                      evt,
                      productId: product.id,
                    });
                  }}
                  canBuy={
                    product.crowdFundingCampaign.status ===
                    CrowdFundingCampaignStatusEnum.in_progress
                  }
                  onBuy={() => {
                    onBuyClick(product.id);
                  }}
                  isFavorite={isFavorite}
                  totalInvested={product.crowdFundingCampaign.totalInvested}
                  daysLeft={calculateDaysLeft(
                    product.crowdFundingCampaign.startDateTime,
                    product.crowdFundingCampaign.endDateTime,
                  )}
                  limit={product.crowdFundingCampaign.successThreshold}
                  progress={Number(product.crowdFundingCampaign.progress)}
                  crowdFundingCampaignStatus={
                    product.crowdFundingCampaign.status
                  }
                  progressToStart={
                    product.crowdFundingCampaign.createdAt
                      ? calculateProgressStartDay(
                          product.crowdFundingCampaign.createdAt,
                          product.crowdFundingCampaign.startDateTime,
                        )
                      : 1
                  }
                  artists={product.artist}
                  productName={product.name}
                  genres={genres}
                />
              );
            })}
          </SimpleGrid>

          <Flex
            mt={{ lg: '80px' }}
            p={{ base: '20px', lg: 0 }}
            gap='20px'
            align='center'
            w='full'
            px={{ lg: 0 }}
          >
            <Button
              variant='solid'
              colorScheme='white'
              w={{ base: 'full', lg: 'auto' }}
              size={{ base: 'md', lg: 'lg' }}
              onClick={onAllCampaigns}
            >
              <Text textStyle='heading-large-sm'>
                {t('HomePage.allCampaignsAction')}
              </Text>
            </Button>

            {!isMobile ? (
              <Text
                textStyle='text-regular-lg'
                color='text&icon.tx-secondary'
              >
                {t('HomePage.allCampaignsActionTitle')}
              </Text>
            ) : null}
          </Flex>
        </Box>
      </Section>

      <HomePartners />

      <Section
        isVisible={Boolean(dataNews?.items.length)}
        mt={{ base: '20px', lg: '40px' }}
        withPadding={isMobile}
        header={t('HomePage.latestNewsTitle')}
        pHeader={{ base: '20px', lg: 0 }}
        rightAction={
          isMobile ? (
            <Link href='/news'>
              <Text textStyle='text-medium-lg'>
                {t('HomePage.latestNewsAll')}
              </Text>
            </Link>
          ) : (
            <Flex gap='20px'>
              <IconButton
                aria-label='back'
                variant='transparent'
                icon={<IconChevronLeft />}
                onClick={() => controlledSwiperNews?.slidePrev()}
              />
              <IconButton
                aria-label='next'
                variant='transparent'
                icon={<IconChevronRight />}
                onClick={() => controlledSwiperNews?.slideNext()}
              />
            </Flex>
          )
        }
      >
        <NewsSwiper
          newsData={dataNews?.items || []}
          controlledSwiperNews={controlledSwiperNews || undefined}
          setControlledSwiperNews={setControlledSwiperNews}
        />
      </Section>

      <FaqBlock />
    </Box>
  );
};

export default HomePage;
